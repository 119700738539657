<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" md="4">
        <criterioActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idAplicacion"
          tipoActividad="examenAplicacion"
          :criterioEvaluacion="propiedadesLocal.criterio"
          :permisoEscritura="permisoEscritura"
          @criterioModificado="criterioModificado"
        />
      </v-col>
      <v-col cols="12" md="4">
        <propiedad-aplicacion
          nombre="Ponderación"
          :valor="ponderacionString"
          :editable="true"
          icono="mdi-file-percent-outline"
          :permisoEscritura="permisoEscritura"
          @editar="abrirModificacionPonderacion"
        ></propiedad-aplicacion>
      </v-col>
      <v-col cols="12" md="4">
        <propiedad-aplicacion
          nombre="Puntos"
          :valor="propiedadesLocal.puntos"
          noAsignadoString="0"
          icono="mdi-chart-arc"
          :permisoEscritura="permisoEscritura"
        ></propiedad-aplicacion>
      </v-col>
      <v-col cols="12" md="4">
        <propiedad-aplicacion
          nombre="Máximo de intentos"
          :valor="propiedadesLocal.intentos"
          :editable="true"
          noAsignadoString="0"
          icono="mdi-chart-arc"
          :permisoEscritura="permisoEscritura"
          @editar="abrirModificacionPropiedad(['intentos'])"
        ></propiedad-aplicacion>
      </v-col>
      <v-col cols="12" md="4">
        <propiedad-aplicacion
          nombre="Modo de calificación"
          :valor="getCalificacionString(propiedadesLocal.calificacion)"
          :editable="true"
          icono="mdi-chart-arc"
          :permisoEscritura="permisoEscritura"
          @editar="abrirModificacionPropiedad(['calificacion'])"
        ></propiedad-aplicacion>
      </v-col>
      <v-col v-if="esCampus" cols="12" md="4">
        <propiedad-aplicacion
          nombre="Fecha de presentación"
          :valor="
            getFechasString({
              inicio: propiedadesLocal.inicio,
              fin: propiedadesLocal.fin,
            })
          "
          :editable="true"
          icono="mdi-calendar-range"
          :permisoEscritura="permisoEscritura"
          @editar="abrirModificacionPropiedad(['inicio', 'fin'])"
        ></propiedad-aplicacion>
      </v-col>
    </v-row>

    <modificacion-aplicacion
      v-if="mostrarModificacion"
      :mostrar="mostrarModificacion"
      :aplicacion="propiedadesLocal"
      :aModificar="aModificar"
      @cancelar="cerrarModificacion()"
      @aplicacionGuardada="aplicacionGuardada"
    ></modificacion-aplicacion>

    <modificacion-ponderacion
      v-if="mostrarModifcacionPonderacion"
      :mostrar="mostrarModifcacionPonderacion"
      :idCurso="idCurso"
      :tipoCurso="tipoCurso"
      :idActividad="idAplicacion"
      :ponderacion="propiedadesLocal.ponderacion"
      :permisoEscritura="permisoEscritura"
      @cancelar="cerrarModificacionPonderacion"
      @ponderacionModificada="ponderacionModificada"
    ></modificacion-ponderacion>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";

import propiedadAplicacion from "./propiedadAplicacion.vue";
import criterioActividadComponent from "../actividades/propiedades/criterioActividad.vue";

export default {
  components: {
    criterioActividadComponent,
    "propiedad-aplicacion": propiedadAplicacion,
    "modificacion-aplicacion": () => import("./modificacionAplicacion.vue"),
    "modificacion-ponderacion": () =>
      import("../actividades/propiedades/modificarPonderacionActividad.vue"),
  },

  props: {
    propiedades: { type: Object, required: true },
    permisoEscritura: { type: Boolean, default: false },
  },

  data() {
    return {
      propiedadesLocal: this.propiedades,
      tipoCurso: null,
      idCurso: null,
      idAplicacion: null,
      mostrarModificacion: false,
      aModificar: [],
      mostrarModifcacionPonderacion: false,
      ponderacionString: "Promedio",
    };
  },

  computed: {
    esCampus() {
      return this.$route.params.tipoCurso === "campus";
    },

    criterioString() {
      if (this.propiedadesLocal.criterio)
        return this.propiedadesLocal.criterio.nombre;
      return "Ninguno";
    },
  },

  watch: {
    propiedades(v) {
      this.propiedadesLocal = v;
    },
  },

  mounted() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.idAplicacion = this.$route.params.idAplicacion;
    this.updatePonderacionString();
  },

  methods: {
    getCalificacionString(calificacion) {
      if (calificacion == "masAlta") return "Intento más alto";
      else return "Promedio de intentos";
    },
    getFechasString({ inicio, fin }) {
      const inicioDateTime = DateTime.fromISO(inicio);
      const finDateTime = DateTime.fromISO(fin);
      if (inicio && fin)
        return `${inicioDateTime.toLocaleString(
          DateTime.DATE_SHORT
        )} - ${finDateTime.toLocaleString(DateTime.DATE_SHORT)}`;
      return "Sin definir";
    },

    cerrarModificacion() {
      this.mostrarModificacion = false;
      this.aModificar = [];
    },
    criterioModificado(data) {
      this.$emit("criterioModificado", data);
    },
    abrirModificacionPropiedad(propiedades) {
      this.aModificar = propiedades;
      this.mostrarModificacion = true;
    },

    aplicacionGuardada(aplicacion) {
      this.aModificar.forEach((propiedad) => {
        if (aplicacion[propiedad]) {
          this.propiedadesLocal[propiedad] = aplicacion[propiedad];
        }
      });
      this.cerrarModificacion();
    },

    abrirModificacionPonderacion() {
      this.mostrarModifcacionPonderacion = true;
    },

    cerrarModificacionPonderacion() {
      this.mostrarModifcacionPonderacion = false;
    },

    ponderacionModificada(emitData) {
      Object.keys(emitData).forEach((key) => {
        this.propiedadesLocal[key] = emitData[key];
      });
      this.updatePonderacionString();
      this.cerrarModificacionPonderacion();
    },

    updatePonderacionString() {
      this.ponderacionString =
        this.propiedadesLocal.ponderacion === -1
          ? "Promedio"
          : `${this.propiedadesLocal.ponderacion}%`;
    },
  },
};
</script>
