<template>
  <div>
    <v-card-title>
      <v-btn
        icon
        :disabled="!anterior"
        @click="$emit('cambiarAplicacion', anterior)"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <span>{{ aplicacion.nombre }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        :disabled="!siguiente"
        @click="$emit('cambiarAplicacion', siguiente)"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <examenes-aplicacion
              v-if="permisoEscritura"
              class="pa-3"
              :examenes="aplicacion.examenes"
            ></examenes-aplicacion>
            <propiedades-aplicacion
              :propiedades="propiedadesAplicacion"
              :permisoEscritura="permisoEscritura"
              @criterioModificado="criterioModificado"
            ></propiedades-aplicacion>
            <div v-if="permisoEscritura">
              <v-divider class="mx-3"></v-divider>
              <opciones-aplicacion
                :opcionesAplicacion="opcionesAplicacion"
                :permisoEscritura="permisoEscritura"
              ></opciones-aplicacion>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!permisoEscritura && !archivado">
      <v-btn
        v-if="resultados && resultadoSinTerminar"
        small
        color="primary"
        :disabled="!esExamenDisponible"
        @click="$emit('continuarAplicacion')"
        >Continuar aplicación</v-btn
      >
      <v-btn
        v-else
        small
        color="primary"
        :disabled="
          !esExamenDisponible ||
          (resultados && aplicacion.intentos == resultados.length)
        "
        @click="$emit('comenzarAplicacion')"
        >{{
          resultados && resultados.length
            ? "Presentar nuevo intento"
            : "Comenzar aplicación"
        }}</v-btn
      >
    </v-container>
    <v-container v-if="resultados && resultados.length">
      <v-card outlined>
        <v-toolbar dense flat color="#eceff1">
          <span>Resultados alcanzados</span>
          <v-spacer></v-spacer>
          <v-chip v-if="calificacion" label color="secondary"
            ><strong
              >Calificación actual: {{ parseInt(calificacion) }}</strong
            ></v-chip
          >
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-for="resultado in resultados" :key="resultado._id">
              <v-list-item-icon>
                <v-icon color="primary">mdi-text-box-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="resultado.terminado"
                  >{{ parseInt(resultado.calificacion) }} Puntos
                  obtenidos</v-list-item-title
                >
                <v-list-item-title v-else
                  >Resultado en proceso</v-list-item-title
                >
                <v-list-item-subtitle v-if="resultado.terminado"
                  >Entregado:
                  {{
                    getFechaLocale(resultado.fechaTerminado)
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-else
                  >Entrega pendiente</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action
                v-if="aplicacionFinalizada && aplicacion.verResultados"
              >
                <v-btn
                  color="primary"
                  small
                  @click="verResultado(resultado._id)"
                >
                  <span>Ver intento</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from "luxon";

import examenesAplicacion from "./examenesAplicacion.vue";
import propiedadesAplicacion from "./propiedadesAplicacion.vue";
import opcionesAplicacion from "./opcionesAplicacion.vue";

export default {
  components: {
    "examenes-aplicacion": examenesAplicacion,
    "propiedades-aplicacion": propiedadesAplicacion,
    "opciones-aplicacion": opcionesAplicacion,
  },

  props: {
    aplicacion: { type: Object, required: true },
    resultados: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
    siguiente: { type: String, default: null },
    anterior: { type: String, default: null },
    calificacion: { type: [String, Number], default: null },
    archivado: { type: Boolean, default: false },
  },

  computed: {
    propiedadesAplicacion() {
      return {
        criterio: this.aplicacion.criterio || null,
        puntos: this.aplicacion.puntos,
        ponderacion: this.aplicacion.ponderacion,
        intentos: this.aplicacion.intentos,
        calificacion: this.aplicacion.calificacion,
        inicio: this.aplicacion.inicio,
        fin: this.aplicacion.fin,
      };
    },

    opcionesAplicacion() {
      return {
        examenAleatorio: this.aplicacion.examenAleatorio || false,
        verResultados: this.aplicacion.verResultados || false,
      };
    },

    esExamenDisponible() {
      if (!this.aplicacion.inicio || !this.aplicacion.fin) return false;

      const dateActual = DateTime.now();
      const dateInicio = DateTime.fromISO(this.aplicacion.inicio);
      const dateFin = DateTime.fromISO(this.aplicacion.fin);
      if (dateActual.toMillis() < dateInicio.toMillis()) return false;
      if (dateActual.toMillis() > dateFin.toMillis()) return false;

      return true;
    },

    resultadoSinTerminar() {
      if (this.resultados && this.resultados.length)
        return this.resultados.find((e) => !e.terminado);
      return null;
    },

    aplicacionFinalizada() {
      return new Date(this.aplicacion.fin).getTime() < new Date().getTime();
    },
  },
  methods: {
    criterioModificado(data) {
      this.$emit("criterioModificado", data);
    },

    getFechaLocale(isoDate) {
      return DateTime.fromISO(isoDate)
        .setLocale("es")
        .setZone("system")
        .toLocaleString(DateTime.DATETIME_SHORT);
    },

    verResultado(idResultado) {
      this.$router.push({
        name: "PresentarExamen",
        params: { idResultado },
      });
    },
  },
};
</script>
