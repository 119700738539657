<template>
  <div>
    <v-list-item
      dense
      @mouseover="puedeModificar && !vistaSeguimiento ? (modificarIcon = true) : null"
      @mouseout="puedeModificar ? (modificarIcon = false) : null"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon"
          fab
          x-small
          color="primary"
          @click="$emit('editar')"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">{{ icono }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ nombre }}</v-list-item-title>
        <v-list-item-subtitle>
          <span>{{ valor || noAsignadoString }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    nombre: { type: String, required: true },
    valor: { type: [String, Number], default: null },
    icono: { type: String, required: true },
    editable: { type: Boolean, defaul: false },
    permisoEscritura: { type: Boolean, default: false },
    noAsignadoString: { type: String, default: "No asignado" },
  },

  data() {
    return {
      criterioLocal: this.criterio,
      modificarIcon: null,
    };
  },

  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    puedeModificar() {
      return this.permisoEscritura && this.editable;
    },
  },
};
</script>
