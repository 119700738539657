<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="examenAleatorio"
                color="primary"
                :disabled="loading"
                :readonly="!permisoEscritura || vistaSeguimiento"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-title>Exámenes aleatorios</v-list-item-title>
          </v-list-item>
        </v-col>
        <v-col v-if="esCampus" cols="12" md="4">
          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="verResultados"
                color="primary"
                :disabled="loading"
                :readonly="!permisoEscritura || vistaSeguimiento"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-title>Ver resultados</v-list-item-title>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { actualizarAplicacion } from "./examenAplicacion.service";

export default {
  props: {
    opcionesAplicacion: {
      type: Object,
      required: true,
    },
    permisoEscritura: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      examenAleatorio: this.opcionesAplicacion.examenAleatorio,
      verResultados: this.opcionesAplicacion.verResultados,
    };
  },

  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },

    esCampus() {
      return this.$route.params.tipoCurso === "campus";
    },
  },

  watch: {
    examenAleatorio(value) {
      this.modificarAplicacion({ examenAleatorio: value });
    },

    verResultados(value) {
      this.modificarAplicacion({ verResultados: value });
    },
  },

  methods: {
    async modificarAplicacion(inputData) {
      this.loading = true;

      try {
        const serverResponse = await actualizarAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.idAplicacion,
          data: inputData,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
