<template>
  <div>
    <v-card outlined>
      <v-toolbar flat dense color="primary" dark>
        <v-toolbar-title class="text-color: #fff">
          <v-icon class="mr-2">{{ examenIcon }}</v-icon>
          <span>Exámenes</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip v-if="!vistaSeguimiento" left offset-overflow color="primary">
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              fab
              color="#fff"
              @click="mostrarAgregado = true"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Agregar examen</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pt-3">
        <span v-if="!examenes.length"
          >No se han agregado exámenes a esta aplicación.</span
        >
        <div v-else>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-card tile flat color="#eceff1">
            <examen-item
              v-for="examen in examenesLocal"
              :key="examen._id"
              :examen="examen"
              :mostrarEliminacion="true"
              :disabled="loading"
              @clickAction="irAExamen"
              @borrar="quitarExamen"
            ></examen-item>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <agregado-examen
      v-if="mostrarAgregado"
      :mostrar="mostrarAgregado"
      @cancelar="mostrarAgregado = false"
      @examenAgregado="examenAgregado"
    ></agregado-examen>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { quitarExamenDeAplicacion } from "./examenAplicacion.service";

import examenItem from "./examenItem.vue";

export default {
  components: {
    "agregado-examen": () => import("./agregadoExamen.vue"),
    "examen-item": examenItem,
  },

  props: {
    examenes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      examenesLocal: this.examenes,
      mostrarAgregado: false,
    };
  },

  computed: {
    ...mapGetters(["examenIcon", "vistaSeguimiento"]),

    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },
  },

  methods: {
    examenAgregado(examen) {
      this.examenesLocal.push(examen);
      this.mostrarAgregado = false;
    },

    irAExamen(examen) {
      this.$router.push({
        name: "AplicacionExamen",
        params: {
          idCurso: this.idCurso,
          idAplicacion: this.idAplicacion,
          idModulo: this.idModulo,
          idExamen: examen._id,
        },
      });
    },

    async quitarExamen(examen) {
      this.loading = true;

      try {
        const serverResponse = await quitarExamenDeAplicacion({
          idCurso: this.idCurso,
          idAplicacion: this.idAplicacion,
          idExamen: examen._id,
        });
        this.loading = false;

        if (serverResponse.ok)
          this.examenesLocal = this.examenesLocal.filter(
            (e) => e._id !== examen._id
          );
        else this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
