import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,[_c(VBtn,{attrs:{"icon":"","disabled":!_vm.anterior},on:{"click":function($event){return _vm.$emit('cambiarAplicacion', _vm.anterior)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VSpacer),_c('span',[_vm._v(_vm._s(_vm.aplicacion.nombre))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","disabled":!_vm.siguiente},on:{"click":function($event){return _vm.$emit('cambiarAplicacion', _vm.siguiente)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"outlined":""}},[(_vm.permisoEscritura)?_c('examenes-aplicacion',{staticClass:"pa-3",attrs:{"examenes":_vm.aplicacion.examenes}}):_vm._e(),_c('propiedades-aplicacion',{attrs:{"propiedades":_vm.propiedadesAplicacion,"permisoEscritura":_vm.permisoEscritura},on:{"criterioModificado":_vm.criterioModificado}}),(_vm.permisoEscritura)?_c('div',[_c(VDivider,{staticClass:"mx-3"}),_c('opciones-aplicacion',{attrs:{"opcionesAplicacion":_vm.opcionesAplicacion,"permisoEscritura":_vm.permisoEscritura}})],1):_vm._e()],1)],1)],1)],1),(!_vm.permisoEscritura && !_vm.archivado)?_c(VContainer,[(_vm.resultados && _vm.resultadoSinTerminar)?_c(VBtn,{attrs:{"small":"","color":"primary","disabled":!_vm.esExamenDisponible},on:{"click":function($event){return _vm.$emit('continuarAplicacion')}}},[_vm._v("Continuar aplicación")]):_c(VBtn,{attrs:{"small":"","color":"primary","disabled":!_vm.esExamenDisponible ||
        (_vm.resultados && _vm.aplicacion.intentos == _vm.resultados.length)},on:{"click":function($event){return _vm.$emit('comenzarAplicacion')}}},[_vm._v(_vm._s(_vm.resultados && _vm.resultados.length ? "Presentar nuevo intento" : "Comenzar aplicación"))])],1):_vm._e(),(_vm.resultados && _vm.resultados.length)?_c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"dense":"","flat":"","color":"#eceff1"}},[_c('span',[_vm._v("Resultados alcanzados")]),_c(VSpacer),(_vm.calificacion)?_c(VChip,{attrs:{"label":"","color":"secondary"}},[_c('strong',[_vm._v("Calificación actual: "+_vm._s(parseInt(_vm.calificacion)))])]):_vm._e()],1),_c(VCardText,[_c(VList,_vm._l((_vm.resultados),function(resultado){return _c(VListItem,{key:resultado._id},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-text-box-check")])],1),_c(VListItemContent,[(resultado.terminado)?_c(VListItemTitle,[_vm._v(_vm._s(parseInt(resultado.calificacion))+" Puntos obtenidos")]):_c(VListItemTitle,[_vm._v("Resultado en proceso")]),(resultado.terminado)?_c(VListItemSubtitle,[_vm._v("Entregado: "+_vm._s(_vm.getFechaLocale(resultado.fechaTerminado)))]):_c(VListItemSubtitle,[_vm._v("Entrega pendiente")])],1),(_vm.aplicacionFinalizada && _vm.aplicacion.verResultados)?_c(VListItemAction,[_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.verResultado(resultado._id)}}},[_c('span',[_vm._v("Ver intento")])])],1):_vm._e()],1)}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }