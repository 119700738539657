import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"flat":"","dense":"","color":"primary","dark":""}},[_c(VToolbarTitle,{staticClass:"text-color: #fff"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.examenIcon))]),_c('span',[_vm._v("Exámenes")])],1),_c(VSpacer),(!_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"left":"","offset-overflow":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","fab":"","color":"#fff"},on:{"click":function($event){_vm.mostrarAgregado = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus-circle")])],1)]}}],null,false,2476038694)},[_c('span',[_vm._v("Agregar examen")])]):_vm._e()],1),_c(VCardText,{staticClass:"pt-3"},[(!_vm.examenes.length)?_c('span',[_vm._v("No se han agregado exámenes a esta aplicación.")]):_c('div',[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c(VCard,{attrs:{"tile":"","flat":"","color":"#eceff1"}},_vm._l((_vm.examenesLocal),function(examen){return _c('examen-item',{key:examen._id,attrs:{"examen":examen,"mostrarEliminacion":true,"disabled":_vm.loading},on:{"clickAction":_vm.irAExamen,"borrar":_vm.quitarExamen}})}),1)],1)])],1),(_vm.mostrarAgregado)?_c('agregado-examen',{attrs:{"mostrar":_vm.mostrarAgregado},on:{"cancelar":function($event){_vm.mostrarAgregado = false},"examenAgregado":_vm.examenAgregado}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }